import React from 'react';
import './Visao.css';

import img from '../../assets/imgs/visao.png';

function Visao() {
  return (
    <div className='Visao-container'>
       <div className='textos'>
       <div className="title">
          <h1 className='h1-red-2'>Essa é a nossa visão</h1>
        </div>

       
        <div className="missaitxt">
        <p>
        Na Aurora, promovemos o autoconhecimento para enfrentar desafios e crescer profissionalmente. Valorizamos a parceria e a colaboração, fortalecendo nossa rede através da troca de experiências. Encorajamos a ambição, impulsionando cada mulher a buscar a melhor versão de si mesma. Esses valores são a base das nossas ações, construindo um futuro brilhante no universo da comunicação.
        </p>
        </div>
       </div>
       <div className='img-fofa'>
        <img src={img} alt="" />
       </div>
    </div>
  );
}

export default Visao;
