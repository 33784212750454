import React from 'react';
import './Missao.css';
import img from '../../assets/imgs/missao.png';

function Missao() {
  return (
    <div className='Missao-container'>
       <div className='textos'>
        <div className="title">
          <h1 className='h1-red-2'>Essa é a nossa missão</h1>
        </div>

        <div className="missaitxt">
          <p>
          O Clube Aurora é um espaço de autoconhecimento, parceria e ambição, onde mulheres da comunicação se unem para desenvolver todo o seu potencial. Através de workshops, mentorias e eventos, impulsionamos o crescimento individual e profissional de cada membro, criando uma rede de apoio mútuo que as impulsiona a alcançar seus objetivos mais ousados.
          </p>
        </div>
       </div>
       <div className='img-fofa'>
        <img src={img} alt="" />
       </div>
    </div>
  );
}

export default Missao;
