import React from "react";
import "./Contato.css";
import img from "../../assets/imgs/rainb.png";
import asterisco from "../../assets/imgs/15.png";

function Contato() {

  const redirectToInstagram = () => {
    window.open('https://www.instagram.com/auroramktclub/', '_blank');
  };
  
  const redirectToLinkedin = () => {
    window.open('https://www.linkedin.com/company/aurora-mkt-club/posts/?feedView=all', '_blank');
  };
  
  return (
    <div class="contato-wrapper">
      <div className="contato-container">

      <img className="asterisco" src={asterisco} alt="" />
  
        <div className="contato-flex">
          <div className="contato-titulos">
            <h1 className="h1-pink">Não perca a chance de ser participante da nossa comunidade.</h1>
            <h4 className="h4-contato"> Siga a Aurora nas redes sociais e acompanhe os melhores posts e eventos.</h4>
          </div>
          <div className="botoes-contato">
            <button  className="seja-apoiador-btn-2"onClick={redirectToLinkedin}>Linkedin</button>
            <button className="seja-apoiador-btn-2"onClick={redirectToInstagram}>Instagram</button>
          </div>
        </div>

        <div>
          <img className="imgrain" src={img} alt="" />
        </div>

       </div>
    </div>
  );
}

export default Contato;
