import React from 'react';
import './Texto2.css';
import Asterisco from '../../assets/imgs/19.svg';

function Texto2() {
  return (
    <div className='Texto2-container'>
      <div className='Texto2-content'>
        <span>
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          EVENTOS <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        </span>
      
      </div>
    </div>
  );
}

export default Texto2;
