import React, { useState } from 'react';
import './Acoes.css';
import img1 from '../../assets/imgs/img1.svg';
import img2 from '../../assets/imgs/img2.svg';
import img3 from '../../assets/imgs/img3.svg';
import img4 from '../../assets/imgs/15.png';


function Acoes() {

  const redirectToInstagram = () => {
    window.open('https://www.instagram.com/auroramktclub/', '_blank');
  };

  return (
    <div className='Acoes-container'>
      <div className='Acoes-textos'>

        <div className='acoes-img'>
          <img className='img-img' src={img1} alt="" />
          <img className='img-img' src={img2} alt="" />
          <img className='img-img' src={img3} alt="" />
        </div>
       

        <div className="espacamento">

          <h1 className='h1-red'>Ser Aurorer é uma experiência</h1>
        
              <p className='inicial'>
                Muito além de encontros para networking, ser Aurorer é uma experiência. Nossos eventos são uma imersão completa do início ao fim, com diversos formatos que englobam de maneira específica o que as profissionais do futuro precisam saber para terem o devido destaque no mercado da comunicação. Meets, workshops, cursos... <span style={{ fontWeight: 800 }}>
      we know how we do it.
    </span>
                  
              </p>
              <button className='seja-apoiador-btn-2' onClick={redirectToInstagram}>
              SAIBA MAIS
            </button>
             
            </div>
            <img className='asterisco' src={img4} alt="" />
   

          </div>
        </div>
      
   
  );
}

export default Acoes;
