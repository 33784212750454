import React from 'react';
import './Home.css';


function Home() {
  return (
    <div className='home-container'>
    </div>
  );
}

export default Home;
