import React from "react";
import "./SejaApoiador.css";
import img from "../../assets/imgs/redstart.svg";

function SejaApoiador() {
  return (
    <div className="SejaApoiador-wrapper">
      <div className="div-blackk">
        <img className="asteriscored" src={img} alt="" />
        <div className="SejaApoiador-container">
          {" "}
          <div className="titulisapoiadores">
            <h1 className="h1-pink">Ficou interessado?</h1>
            <h1 className="h1-pink">
              Troque uma ideia conosco e apoie a comunidade!
            </h1>
          </div>
          <form className="SejaApoiador-form">
            <div className="form-group">
              <input
                type="text"
                id="nome"
                name="nome"
                placeholder="Nome"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="tel"
                id="telefone"
                name="telefone"
                placeholder="Telefone"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                required
              />
            </div>
            <div className="form-group">
              <div className="flex-container">
                <input
                  type="text"
                  id="empresa"
                  name="empresa"
                  placeholder="Empresa"
                  required
                />

                <select id="estado" name="estado" required>
                  <option value="">Selecione o estado</option>
                  <option value="AC">Acre</option>
                  <option value="AL">Alagoas</option>
                  <option value="AP">Amapá</option>
                  <option value="AM">Amazonas</option>
                  <option value="BA">Bahia</option>
                  <option value="CE">Ceará</option>
                  <option value="DF">Distrito Federal</option>
                  <option value="ES">Espírito Santo</option>
                  <option value="GO">Goiás</option>
                  <option value="MA">Maranhão</option>
                  <option value="MT">Mato Grosso</option>
                  <option value="MS">Mato Grosso do Sul</option>
                  <option value="MG">Minas Gerais</option>
                  <option value="PA">Pará</option>
                  <option value="PB">Paraíba</option>
                  <option value="PR">Paraná</option>
                  <option value="PE">Pernambuco</option>
                  <option value="PI">Piauí</option>
                  <option value="RJ">Rio de Janeiro</option>
                  <option value="RN">Rio Grande do Norte</option>
                  <option value="RS">Rio Grande do Sul</option>
                  <option value="RO">Rondônia</option>
                  <option value="RR">Roraima</option>
                  <option value="SC">Santa Catarina</option>
                  <option value="SP">São Paulo</option>
                  <option value="SE">Sergipe</option>
                  <option value="TO">Tocantins</option>
                </select>
              </div>
            </div>
          </form>{" "}
          <div className="form-grande">
            <input
              type="text"
              id="mensagem"
              name="mensagem"
              placeholder="Escreva sua mensagem..."
              required
              rows="4"
              cols="50" 
              
            />
          </div>
          <button className="seja-apoiador-btn-3" type="submit">
            Enviar
          </button>
        </div>
      </div>
    </div>
  );
}

export default SejaApoiador;
