const type = "Button";

const menuItems = [
    {
        label: "aurora who?",
        type: type,
        to: "aurorawho",
    },
    {
        label: "eventos",
        type: type,
        to: "eventos",

    },
    {
        label: "nossa vibe",
        type: type,
        to: "missao",
    },
    {
        label: "socials",
        type: type,
        to: "socials",
    }

];

export default menuItems;
