import React from "react";
import { Link } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {

    const main = process.env.REACT_APP_MAIN;

    const scrollUp = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };



    return (
        <div className="notfound-container">
            <h1>Página não encontrada</h1>
            <h2>Erro 404</h2>
            <p>A página que você está procurando não existe.</p>
            <Link onClick={scrollUp} to={main}>Voltar para o site principal</Link>
        </div>
    );
};

export default NotFound;
