import React from "react";
import "./SOmos.css";
import img from "../../assets/imgs/auroraewho.svg";

function SOmos() {
  return (
    <div className="somoswrapper">
      <div className="somoscontainer">
        <div className="somoscontent">
          <img className="auroraimg" src={img} alt="" />

          <div className="texto-somos">
            <p>
              Como a deusa que se renova a cada amanhecer, a Aurora nasceu de
              uma força:{" "}
              <span style={{ fontWeight: 800 }}>
                crescer como hub monetizável de jobs
              </span>
              , infoprodutos dentro de uma comunidade exclusivamente feminina
              para mulheres no marketing.
            </p>
            <p>
              No fim das contas a gente faz de tudo e ainda vende os merchs mais
              legais que você vai ver por toda parte.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SOmos;
