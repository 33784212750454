import "./Footer.css";
import React, { useMemo } from "react";
import Logo from '../../assets/imgs/logo.svg';
import menuItems from './Btns-Content';
import MenuBtn from './MenuBtn';


const Footer = ({ scrollToComponent }) => {

  return (
    <footer className="footer-background">
      <div className="footer-container header-body-footer-width">
        <div class="footer-container-division">

          <div className="footer-wrap">
            <div className="footer-logo">
              <img className="footer-img" src={Logo} alt="logo"></img>
            </div>
            <div></div>
            <div>
              
            </div>
          </div>
          <div className="footer-wrap footer-content">
            
      
            <div className="footer-menu">
              <h3 className="h1-red-2">Ser Aurorer é um Experiência</h3>
              <div>
                
                <p>Meets</p><p>Mentorias</p> <p>
                  Workshops
                </p>
                
                <p>Networking</p>
                
                <p>Geração de Receita</p>
                <p>E muito mais...</p>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-wrap footer-bottom-container">
          <div className="footer-copy">
            <p>&copy; 2024 Gabriella Aguiar</p><a className="luana-red"
              href="https://wa.me/5584996060201"
              aria-label="Whatsapp Icon"
              target="_blank"
              rel="noreferrer"
            > Desenvolvido por Luana Salmito
              
            </a>
          </div>
          
          <div className="footer-socials">
            <a
              href="https://www.linkedin.com/company/auroramktclub/"
              target="_blank"
              rel="noreferrer"
              aria-label="site principal do escritório"
            >
              <i className="uil uil-linkedin-alt" style={{ color: "white" }}></i>
            </a>

            <a
              href="https://www.instagram.com/auroramktclub/"
              target="_blank"
              aria-label="site principal do escritório"
              rel="noreferrer"
            >
              <i className="uil uil-instagram" style={{ color: "white" }}></i>
            </a>

            <a
              href="https://wa.me/5584998042875"
              aria-label="Whatsapp Icon"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-whatsapp" style={{ color: "white" }}></i>
            </a>

    
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
