import React from 'react';
import './Texto3.css';
import Asterisco from '../../assets/imgs/19.svg';

function Texto3() {
  return (
    <div className='Texto2-container'>
      <div className='Texto2-content'>
        <span>
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
          NOSSOS VALORES <img className='img-mkt2' src={Asterisco} alt="Meet Aurora" />
        </span>
      
      </div>
    </div>
  );
}

export default Texto3;
