import React from 'react';
import './Apoiadores.css';
import apoiadores from '../../assets/imgs/logosapoiadores.jpg';
import apoiadoresmobile from '../../assets/imgs/apoiadoresmobile.svg';

function Apoiadores() {
  return (
    <div className='Apoiadores-container'>
        <div className='titles'>
          <h1 className='h1-red'>Quem esteve com a Auroh</h1>
        </div>

        <div className='apoiadores'>
          <picture>
            <source media="(max-width: 600px)" srcSet={apoiadoresmobile} />
            <source media="(min-width: 601px)" srcSet={apoiadores} />
            <img className='apoiadoress' src={apoiadores} alt="Logos dos apoiadores" />
          </picture>
        </div>
    </div>
  );
}

export default Apoiadores;
