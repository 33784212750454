import React, { useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import Home from "./components/home/Home";
import Texto2 from "./components/Texto2/Texto2";
import Texto3 from "./components/Texto3/Texto3";
import Contato from "./components/contato/Contato";
import Somos from "./components/SOmos/SOmos";
import Acoes from "./components/Acoes/Acoes";
import Missao from "./components/Missao/Missao";
import Visao from "./components/Visao/Visao";
import Apoiadores from "./components/Apoiadores/Apoiadores";
import SejaApoiador from "./components/SejaApoiador/SejaApoiador";
import Shop from "./components/shop/Shop";
import NotFound from "./components/notfound/NotFound";

import "./App.css";
import "./assets/styles/Reset.css";

function App() {
  const main = process.env.REACT_APP_MAIN;

  const aurorawho = useRef();
  const eventos = useRef();
  const missao = useRef();
  const socials = useRef();
  const sejaapoiador = useRef();

  const scrollToComponent = (refName) => {
    const refs = {
      aurorawho,
      eventos,
      missao,
      socials,
      sejaapoiador,
    };

    const selectedRef = refs[refName];

    if (selectedRef && selectedRef.current) {
      var yOffset;
      if (refName === "InicioRef") {
        yOffset = -180;
      } else {
        yOffset = -120;
      }

      const yCoordinate =
        selectedRef.current.getBoundingClientRect().top +
        window.scrollY +
        yOffset;
      window.scrollTo({ top: yCoordinate, behavior: "smooth" });
    }
  };
  return (
    <Router>
      <div className="App">
        <Header scrollToComponent={scrollToComponent} />

        <Routes>
          <Route
            path={main}
            element={
              <>
                <div class="body-wrapper">
                  <div class="body-container">
                    <Home />
                    <div ref={aurorawho}>
                      <Somos />
                    </div>

                    <Texto2 />

                    <div ref={eventos}>
                      <Acoes />
                    </div> 
                    <Texto3 />
                  
                   
                    <div ref={missao}>
                      <Missao />
                    </div>

                    <Visao />
                      <div ref={socials}>
                      <Contato />
                    </div>
                    <Apoiadores />
                    <div ref={sejaapoiador}>
                      <SejaApoiador />
                    </div>
                  </div>
                </div>
              </>
            }
          ></Route>

          <Route path="*" element={<NotFound />} />
          {/*           <Route path="/shop" element={<Shop />} /> */}
        </Routes>

        <Footer scrollToComponent={scrollToComponent} />
      </div>
    </Router>
  );
}

export default App;
