import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import MenuBars from "../../assets/imgs/menu-bars.svg";
import logo from "../../assets/imgs/auroralogobranca.svg";
import { useNavigate } from "react-router-dom";
import Menu from "./Menu";
import Texto from "../Texto/Texto";
import "./Header.css";
import menuItems from "./Btns-Content";
import MenuBtn from "./MenuBtn";

const Header = ({ scrollToComponent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const action = (address) => {
    if (address !== "Link") {
      scrollToComponent(address);
    } else {
      scrollUp();
    }
  };

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };



  const main = process.env.REACT_APP_MAIN;

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = async () => {
    await navigate("/"); // Navigate to the main route
    scrollToComponent("portfolio"); // Scroll to the portfolio section
  };

  const memoizedMenuItems = useMemo(() => {
    return menuItems.map((menuItem, index) => (
      <div key={index}>
        <MenuBtn
          action={action}
          data={menuItem}
          scrollToComponent={scrollToComponent}
        />
      </div>
    ));
  }, [menuItems]);

  return (
    <div className="header-with-text">

      <div
        className={`main-header ${isScrolled ? "scrolled" : ""} header-wrapper`}
      >
        <div className="header-container">
          <Link to={main} onClick={scrollUp}>
            <div className="logo">
              <img className="logo-img" src={logo} alt="Logo" />
            </div>
          </Link>
          <nav>
            {memoizedMenuItems}
            <button className="seja-apoiador-btn" onClick={() => action('sejaapoiador')}>SEJA APOIADOR </button>
          </nav>


          <button className="menu-toggle" onClick={toggleSidebar}>
            <img className="btn-menu" src={MenuBars} alt="Menu Bars" />
          </button>
        </div>
        <Menu
          scrollToComponent={scrollToComponent}
          toggleSidebar={toggleSidebar}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </div>
    </div>
  );
};

export default Header;
