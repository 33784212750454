import React from 'react';
import './Shop.css';

function Shop() {
  return (
    <div className='shop-container'>
        Shop
    </div>
  );
}

export default Shop;
